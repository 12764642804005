import React, { useEffect, useState } from 'react';
import './index.css';

const WarningMessage = () => {
  const [showWarning, setShowWarning] = useState(false);
  const [releaseDate, setReleaseDate] = useState(null);

  useEffect(() => {
    fetch('http://release.ofnur.com/portolia')
      .then(response => response.text())
      .then(data => {
        const currentDate = new Date();
        const release = new Date(data);
        const differenceDays = release - currentDate;
        const day = 86400000;
        setShowWarning(differenceDays <= day && differenceDays >= 0);
        setReleaseDate(release);
      })
      .catch(error => console.error('Error fetching the text:', error));
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowWarning(false);
    }, 30000); // Hide warning after 30 seconds

    return () => clearTimeout(timer);
  }, []);

  const formatMaintenanceMessage = () => {
    if (!releaseDate) return ''; // Handle case where releaseDate is null
    // Format the releaseDate as required
    const formattedDate = releaseDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    // Calculate end time (releaseDate + 1 hour)
    const endDate = new Date(releaseDate.getTime());
    endDate.setHours(releaseDate.getHours() + 1);

    // Format the maintenance window details
    const startTime = releaseDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });
    const endTime = endDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });

    return `This website might be unavailable on ${formattedDate}, from ${startTime} to ${endTime} (UTC) due to maintenance.`;
  };

  return (
    <div>
      {showWarning && (
        <div className='warning-message' onClick={() => setShowWarning(false)}>
          <p>
            <strong>Warning: </strong> {`${formatMaintenanceMessage()}`}
          </p>
        </div>
      )}
    </div>
  );
};

export default WarningMessage;
