import React from 'react';
import qrcode from '../../assets/qrcode.svg';
import './index.css';

const PrintText = () => {
  return (
    <div className='print-text'>
      <h1>Nuriddin Bobonorov</h1>
      <h2>Software Engineer</h2>

      <h3>Contact Information:</h3>
      <p>Phone: +1 (929) 366 0505</p>
      <p>Email: <a href='mailto:sulaymonyahyo@icloud.com'>sulaymonyahyo@icloud.com</a> | <a
        href='mailto:sulaymon1w@gmail.com'>sulaymon1w@gmail.com</a></p>
      <p>LinkedIn: <a href='https://www.linkedin.com/in/SulaymonYahyo'>Sulaymon Yahyo</a></p>
      <p>GitHub: <a href='https://github.com/Sulaymon-Dev20'>Sulaymon-Dev20</a></p>
      <p>LeetCode: <a href='https://leetcode.com/Sulaymon-Dev20'>Sulaymon-Dev20</a></p>

      <h3>Professional Summary:</h3>
      <p>Experienced Software Engineer with a primary focus on map technologies and geographic information systems
        (GIS). Specializing in backend engineering with Java/Spring Boot and frontend development with ReactJS.
        Proficient in iOS development with SwiftUI. Skilled in DevOps practices, including CI/CD pipelines, Docker, and
        cloud infrastructure management. Effective communicator with a passion for problem-solving and delivering
        high-quality software solutions.</p>

      <h3>Technical Skills:</h3>
      <ul>
        <li><strong>Backend:</strong> Java, Spring Boot, Spring Security, Spring Cloud, Netflix Eureka, JPA,
          JdbcTemplate, Thymeleaf, Lombok, REST API, JWT, Telegram Bots, Twilio, Kafka, i18n, PostgreSQL, MongoDB
        </li>
        <li><strong>Frontend:</strong> JavaScript, ReactJS, Axios, React-Redux, Redux, Leaflet, i18next, Material UI
        </li>
        <li><strong>DevOps:</strong> CI/CD, GitLab, Docker, Kubernetes, Bash Scripting, SSH, DigitalOcean, Crons, AWS
          (EC2, S3), Firewalls (iptables, UFW)
        </li>
        <li><strong>iOS:</strong> SwiftUI, Localization, MVVM, UIKit, CoreData, Swift</li>
        <li><strong>Map & GIS:</strong> Mapbox, Google Maps API, Leaflet, OpenLayers, Esri ArcGIS, QGIS, GeoJSON</li>
      </ul>

      <h3>Work Experience:</h3>
      <h4>Software Engineer | EcoPay</h4>
      <ul>
        <li>Led the development of location-based services using Java and Spring Boot.</li>
        <li>Implemented map visualization features utilizing Google-map and Leaflet.</li>
        <li>Integrated geospatial data into applications for mapping and analysis purposes.</li>
        <li>Designed and developed GIS solutions for various industries including transportation and logistics.</li>
      </ul>

      <h4>Software Developer | Smart Software Development</h4>
      <ul>
        <li>Served as a Team Leader on various projects, demonstrating leadership and organizational skills.</li>
        <li>Contributed to the success of the company by leading projects that resulted in winning tenders for auto
          transport projects in the Republic of Uzbekistan.
        </li>
      </ul>

      <h4>Ministry of Internal Affairs of Uzbekistan</h4>
      <ul>
        <li>Held roles as both front-end and back-end developer, overseeing functions responsible for generating
          important documents, and subway maps of Tashkent.
        </li>
        <li>Played a crucial role in the development and maintenance of systems essential for mapping and reviewing
          important documents and subway routes in Tashkent.
        </li>
      </ul>

      <h4>Freelance Software Engineer</h4>
      <ul>
        <li>Worked on projects related to Quran Karim and Muazzin, contributing to both iOS development and backend
          systems for Quran Karim.
        </li>
        <li>Involved in frontend and backend development for projects with global reach, ensuring compatibility and
          functionality across different regions and cultures.
        </li>
      </ul>

      <h4>Software Engineer | Ministry of Cadastre</h4>
      <ul>
        <li>Proficient in ensuring the security of servers, including IP security measures and implementation of custom
          VPN solutions.
        </li>
        <li>Experienced in setting up and managing backup solutions for project home assets, ensuring data integrity and
          disaster recovery.
        </li>
        <li>Well-versed in working with DevOps principles and practices to streamline development workflows and improve
          collaboration between development and operations teams.
        </li>
      </ul>

      <h3>Languages</h3>
      <p>Proficient in Uzbek (Native)</p>
      <p>Intermediate proficiency in English</p>
      <p>Basic proficiency in Russian (A2)</p>

      <h3>Additional Information:</h3>
      <p>Current location: New York City, NY, US</p>
      <p>Willing to relocate if necessary</p>
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <h1>Welcome to My Resume</h1>
        <p>Scan this QR code to visit my portfolio and resume:</p>
        <img src={qrcode} alt="QR code" style={{ width: '10%' }} />
      </div>
    </div>
  );
};

export default PrintText;