export const navLinks = [
  {
    label: 'projects',
    pathname: '/',
    hash: '#project-1',
  },
  {
    label: 'details',
    pathname: '/',
    hash: '#details',
  },
  // {
  //   label: 'Articles',
  //   pathname: '/articles',
  // },
  {
    label: 'uses',
    pathname: '/uses',
  },
  {
    label: 'contact',
    pathname: '/contact',
  },
];

export const socialLinks = [
  {
    label: 'Leetcode',
    url: 'https://leetcode.com/Sulaymon-dev20',
    icon: 'twitter',
  },
  {
    label: 'github',
    url: 'https://github.com/Sulaymon-Dev20',
    icon: 'github',
  },
  {
    label: 'LinkedIn',
    url: 'https://www.linkedin.com/in/sulaymon-yahyo/',
    icon: 'Linkedin',
  }
];
