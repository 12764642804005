import './reset.css';
import './index.css';

import { initialState, reducer } from 'app/reducer';
import classNames from 'classnames';
import Navbar from 'components/Navbar';
import ThemeProvider from 'components/ThemeProvider';
import { tokens } from 'components/ThemeProvider/theme';
import VisuallyHidden from 'components/VisuallyHidden';
import { useLocalStorage } from 'hooks';
import { createContext, Fragment, lazy, Suspense, useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { Transition, TransitionGroup } from 'react-transition-group';
import prerender from 'utils/prerender';
import { msToNum } from 'utils/style';
import { reflow } from 'utils/transition';
import Loading from '../pages/Home/Loading';
import Resume from '../pages/Resume';
import WarningMessage from '../components/WarningMessage';

const Home = lazy(() => import('pages/Home'));
const Contact = lazy(() => import('pages/Contact'));
const ProjectSPR = lazy(() => import('pages/SmartSparrow'));
const ProjectHolyBook = lazy(() => import('pages/HolyBook'));
const ProjectVolkihar = lazy(() => import('pages/VolkiharKnight'));
const Page404 = lazy(() => import('pages/404'));
const Uses = lazy(() => import('pages/Uses'));

export const AppContext = createContext();
export const TransitionContext = createContext();

const repoPrompt = `
   ___    _  _     ___   
  | _ )  | \\| |   | _ )  
  | _ \\  | .\` |   | _ \\  
  |___/  |_|\\_|   |___/  
_|"""""|_|"""""|_|"""""|
"\`-0-0-'"\`-0-0-'"\`-0-0-' \nNuriddin Bobonorov PortFolio 
`;

const App = () => {
  const [storedTheme] = useLocalStorage('theme', 'light');
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!prerender) {
      console.info(`${repoPrompt}\n\n`);
    }
    window.history.scrollRestoration = 'manual';
  }, []);

  useEffect(() => {
    dispatch({ type: 'setTheme', value: storedTheme });
  }, [storedTheme]);

  return (
    <div>
      <div className={"normal-content"}>
        <Suspense fallback={<Loading />}>
          <AppContext.Provider value={{ ...state, dispatch }}>
            <ThemeProvider themeId={state.theme}>
              <BrowserRouter>
                <AppRoutes />
              </BrowserRouter>
            </ThemeProvider>
          </AppContext.Provider>
        </Suspense>
      </div>
      <Resume/>
    </div>
  );
};

const AppRoutes = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <Fragment>
      <Helmet>
        <link rel='canonical' href={`https://portfolio.ofnur.com${pathname}`} />
      </Helmet>
      <VisuallyHidden showOnFocus as='a' className='skip-to-main' href='#MainContent'>
        Skip to main content
      </VisuallyHidden>
      <Navbar location={location} />
      <WarningMessage/>
      <TransitionGroup component='main' className='app' tabIndex={-1} id='MainContent'>
        <Transition
          key={pathname}
          timeout={msToNum(tokens.base.durationS)}
          onEnter={reflow}
        >
          {status => (
            <TransitionContext.Provider value={{ status }}>
              <div className={classNames('app__page', `app__page--${status}`)}>
                <Suspense fallback={<Fragment />}>
                  <Routes location={location} key={pathname}>
                    <Route path='/' element={<Home />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/projects/asdum' element={<ProjectSPR />} />
                    <Route path='/projects/holybook' element={<ProjectHolyBook />} />
                    <Route
                      path='/projects/my-bus'
                      element={<ProjectVolkihar />}
                    />
                    <Route path='/uses' element={<Uses />} />
                    <Route path='*' element={<Page404 />} />
                  </Routes>
                </Suspense>
              </div>
            </TransitionContext.Provider>
          )}
        </Transition>
      </TransitionGroup>
    </Fragment>
  );
};

export default App;
